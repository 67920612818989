table {
    width: 80%;
    border-collapse: collapse;
}

table, th, td {
    border: 1px solid grey;
    display: table-cell;
}

th {
    padding: 0 1rem;
}

.csrId {
    font-weight: bold;
    margin-bottom: 2rem;
}
