@tailwind base;

@tailwind components;

@tailwind utilities;

html, body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;    color: #242934;
    height: 100%;
}

#root {
    height: 100%;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Arial', sans-serif;
    font-weight: bold;
    color: #242934;
}

h1 {
    font-size: 38px;
}

h2 {
    font-size: 30px;
}

h3 {
    font-size: 24px;
}

h4 {
    font-size: 20px;
}

h5, h6 {
    font-size: 18px;
}

.error-panel {
    display: flex;
    flex-direction: row;
    padding: 12px 18px 12px 12px;
    margin-top: 8px;
    background-color: #fcefe6;
    border-left: solid 6px #cc6026;
}

.error-link {
    border: 0;
    color: #2e5299;
    font-weight: normal;
    text-decoration: underline;
    text-underline-position: under;
}

.error-link a:hover {
    color: #365294;
}

.error-note {
    list-style-type:disc;
    list-style-position: inside;
}
